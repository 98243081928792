import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import usePrivateApi from '../../../hooks/usePrivateApi';
import { setActiveProductoAuditado, startSaveProductoAuditado } from '../../../store/auditorias';
import Swal from 'sweetalert2';
import moment from 'moment';

export const FormularioProductoAuditado = () => {    
    const { api } = usePrivateApi();
    const dispatch = useDispatch();

    const {productoAuditadoActivo} = useSelector(state => state.auditorias);
    const userLogged = useSelector(state => state.user);   
    

    const { register, handleSubmit, formState: { errors }, reset, setValue} = useForm();
    
    const onProductoSubmit = values => { 
        const productoAuditadoAux = {      
            ...productoAuditadoActivo,
            usuario:userLogged,
            cantidadFraccionesInventario:values.cantidadFraccionesInventario,
            cantidadPaquetesInventario:values.cantidadPaquetesInventario,
            fechaInventario:moment().valueOf(),
        } 
        dispatch(setActiveProductoAuditado(productoAuditadoAux));        

        Swal.fire({
            title: '¿Guardar cambios de inventario?',  
            text: "",
            icon: 'warning',    
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Sí, guardar los cambios!',
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return dispatch(startSaveProductoAuditado({api}))                     
                .then(response => {                        
                  if (!response.ok) {
                     throw new Error(response.statusText)
                   }
                   return response.json();
                })
                .catch(error => {
                  dispatch(setActiveProductoAuditado({
                    ...productoAuditadoAux,
                    cantidadFraccionesInventario:null,
                    cantidadPaquetesInventario:null,
                    fechaInventario:null,
                  }));
                  Swal.showValidationMessage(
                    `Requerimiento Fallido: ${error}`
                  )
                })
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            if (result.isConfirmed) {        
              Swal.fire({
                title: `${result.value}`,          
              })
            }
          })
            .catch(error => {
                dispatch(setActiveProductoAuditado({
                    ...productoAuditadoAux,
                    cantidadFraccionesInventario:null,
                    cantidadPaquetesInventario:null,
                    fechaInventario:null,
                  }));
            })
          ;
        
    };

    useEffect(() => {      
        setValue('cantidadFraccionesInventario', productoAuditadoActivo.cantidadFraccionesInventario);
        setValue('cantidadPaquetesInventario', productoAuditadoActivo.cantidadPaquetesInventario);

      return () => {
        reset({
            cantidadFraccionesInventario:'',
            cantidadPaquetesInventario: '',
          
          })
      }
    }, [productoAuditadoActivo])
    

    return (
        <>
            <div className="flex min-h-full items-center justify-center px-4 py-2 sm:px-6 lg:px-8">
                <div className="w-full max-w-md space-y-8">
                    <div>   
                        <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">Producto</h2>
                            <dl className="mt-3 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-3 sm:gap-y-16 lg:gap-x-8">
                                <div key="1" className="border-t border-gray-200 pt-4">
                                    <dt className="font-medium text-gray-900">{productoAuditadoActivo.nombre}</dt>
                                    <dd className="mt-2 text-sm text-gray-500">Nombre</dd>
                                </div>

                                <div key="2" className="border-t border-gray-200 pt-4">
                                    <dt className="font-medium text-gray-900">{productoAuditadoActivo.presentacion}</dt>
                                    <dd className="mt-2 text-sm text-gray-500">Presentación</dd>
                                </div>


                                <div key="3" className="border-t border-gray-200 pt-4">
                                    <dt className="font-medium text-gray-900">{productoAuditadoActivo.codigoBarra}</dt>
                                    <dd className="mt-2 text-sm text-gray-500">Código barra</dd>
                                </div>

                                {/* <div key="4" className="border-t border-gray-200 pt-4">
                                    <dt className="font-medium text-gray-900">{productoAuditadoActivo.producto.laboratorio.nombre}</dt>
                                    <dd className="mt-2 text-sm text-gray-500">Laboratorio</dd>
                                </div> */}
                            </dl>
                    </div>   
                    <form onSubmit={handleSubmit(onProductoSubmit)}
                      className="mt-8 space-y-6" 
                      id="idFormProducto">
                        <div className='mt-3 grid grid-cols-1 gap-x-6 sm:grid-cols-2'>

                            <div>
                                <label htmlFor="cantidadFraccionesInventario" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    Cant. Fracciones
                                </label> 
                                <input
                                    type="Number"
                                    name="cantidadFraccionesInventario"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                    autoComplete="off"                                
                                    placeholder=''                                    
                                    {...register("cantidadFraccionesInventario", 
                                                    {required: "Cant.fracciones es requerido",
                                                    min: 0, max: 999999 },
                                                    )
                                        }
                                />
                                {<p className="text-red-600">{errors.cantidadFraccionesInventario?.message} </p>}                            
                            </div>

                            <div>
                                <label htmlFor="cantidadPaquetesInventario" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    Cant. Paquetes
                                </label> 
                                <input
                                    type="Number"
                                    name="cantidadPaquetesInventario"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                    autoComplete="off"                                
                                    placeholder=''                                    
                                    {...register("cantidadPaquetesInventario", 
                                                    {required: "Cant.Paquetes es requerido",
                                                    min: 0, max: 999999 },
                                                    )
                                        }
                                />
                                {<p className="text-red-600">{errors.cantidadPaquetesInventario?.message} </p>}                            
                            </div>
                        </div>    

                        <div>
                            <button type="submit"
                                className={`group relative flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                            >
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">                                
                                    <FontAwesomeIcon icon={faPenToSquare} />
                                </span>
                                Guardar
                            </button>
                        </div>

                    </form>    

                
                </div>    
            </div>    
        </>
    )
}

import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select, { createFilter } from 'react-select';
import { setActiveProductoAuditado } from '../../../../store/auditorias';

export const CardProductoAuditoriaOpciones = () => {
    
    const dispatch = useDispatch(); 

    const { auditoriaActiva } = useSelector(state => state.auditorias);

    const [productosFiltrados, setProductosFiltrados] = useState([]);

    const radioSinAuditarRef = useRef();

    const onSelectCondProductoAuditoria =(e)=>{                
        const auxColProductos = auditoriaActiva.productos.filter(prod=>
            (e.target.value === 'SinAuditar' && prod.usuario === null)
            ||
            (e.target.value === 'Auditados' && prod.usuario !== null)
            ||
            (e.target.value === 'All')
        );        
        
        setProductosFiltrados(auxColProductos.map((item)=>({
            value:item.id,
            label:`${item.nombre} - (${item.presentacion})`,
            codigoBarras: item.codigoBarra,
        })));

        dispatch(setActiveProductoAuditado(null));
        
    }

    const handleChangeProductoAAuditar =(e)=>{        
        if(e === null ){
            dispatch(setActiveProductoAuditado(null));
            return;
        } 
        const productoAux = auditoriaActiva.productos.find((prod)=> prod.id === e.value);
        dispatch(setActiveProductoAuditado(productoAux));
    }

    useEffect(() => {  
        //inicialmente filtro los productos sinAuditar        
        const auxColProductos = auditoriaActiva.productos.filter(prod=>prod.usuario === null);
        setProductosFiltrados(auxColProductos.map((item)=>({
            value:item.id,
            //label:`${item.producto.nombre} - (${item.producto.presentacion})`,
            label:`${item.nombre} - (${item.presentacion})`,
            codigoBarras: item.codigoBarra,
            }
        )));
        //seteo del radio button por default en la opcion SinAuditar
        radioSinAuditarRef.current.checked=true;
        
    }, [auditoriaActiva])
    
    const filterConfig = {
        ignoreCase: true,
        ignoreAccents: true,
        matchFrom: 'any',
        stringify: option => {
           //console.log('------',option);
           return `${option.data.codigoBarras} ${option.label} ${option.value}`
        },
        trim: true,
      };
    
  return (
    <>
    
        <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
            <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                <div className="flex items-center pl-3">
                    <input ref={radioSinAuditarRef} onChange={onSelectCondProductoAuditoria} id="horizontal-list-radio-sinAuditar" type="radio" value="SinAuditar" name="list-radio-producto" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
                    <label htmlFor="horizontal--producto-sinAuditar" className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Sin auditar</label>
                </div>
            </li>
            <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                <div className="flex items-center pl-3">
                    <input onChange={onSelectCondProductoAuditoria} id="horizontal-list-radio-auditados" type="radio" value="Auditados" name="list-radio-producto" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
                    <label htmlFor="horizontal-list-radio-auditados" className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Auditados</label>
                </div>
            </li>
            <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                <div className="flex items-center pl-3">
                <input onChange={onSelectCondProductoAuditoria} id="horizontal-list-radio-all" type="radio" value="All" name="list-radio-producto" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
                    <label htmlFor="horizontal-list-radio-all" className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Todos</label>
                </div>
            </li>            
        </ul>    
        <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
            <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                <Select            
                    className="basic-single"
                    classNamePrefix="select"                                                        
                    isClearable={true}                    
                    isSearchable={true}
                    //value={productosFiltrados[0]}
                    name="productos"                    
                    options={productosFiltrados}
                    onChange={handleChangeProductoAAuditar}
                    noOptionsMessage={({ inputValue }) =>"Sin resultados para: " + inputValue}
                    filterOption={createFilter(filterConfig)} 
                />                    
            </li>
        </ul>    

        
            
        
    </>
            
  )
}
